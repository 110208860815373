import sortBar from './components/SortBar'
import { closeProble, custProblemPage, evaluate, selectReply } from "@/views/center/consultNewPage/list/api";
import addQuestion from './components/addQuestion'
import { mapState } from "vuex";
import appeal from './components/appealWindows'
export default {
  components: {
    sortBar,
    addQuestion,
    appeal
  },
  data() {

    return {
      pageLoadFlag:false,
      type:'',
      appealVisible:false,
      SStype:false,
      visibleTips:false,
      mesageInfoTips:'',
      questionVisible:false,
      // 面包屑数据
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/center/consultNewPage",
          name: "consultNewPage",
          title: '问题反馈明细'
        }
      ],
      sortList:[
        {
          name: "全部时间",
          sortName:'',
          sortStatus: 0 //0默认 1降 2升序
        },
        {
          name: "创建时间",
          sortName:'createdDate',
          sortStatus: 0 //0默认 1降 2升序
        },
        {
          name: "回复时间",
          sortName:'replyDate',
          sortStatus: 1 //0默认 1降 2升序
        }

      ],

      todoList:[],
      evaluateStatusList: [
        {id: 1, name: '非常不满意'},
        {id: 2, name: '不满意'},
        {id: 3, name: '一般般'},
        {id: 4, name: '满意'},
        {id: 5, name: '非常满意'},
      ],
      evaluateList: [], // 物流评价标签

      closeId:'',
      otiontype:'',
      filterFrom:{
        pageNo:1,
        pageSize:10,
        orderBy:'replyDate desc',//默认创建时间
      },
      totalPages:0,
      currentPage:0,
      totalRows:0,
      isSearch:true,
      closeBoxFlag:false,
      closeBoxclickFlag:true,
      timer:null,
      showlaba:false,
      num:0,
      timer2:null,
      showflag:true,
    };
  },
  computed: {
    ...mapState({
      //处理后的筛选数据list
      filList: (state) => state.filtrate.filList,
      //筛选组件加载loadding
      filterLoadFlag: (state) => state.filtrate.isLoading,
      //用于查询列表的筛选配置字段
      filterConfigStr: (state) => state.filtrate.filterConfigStr,
      isModelShow: (state) => state.user.isModelShow,
    }),
    userInfo() {
      return this.$store.state.user.userInfo
        ? this.$store.state.user.userInfo
        : localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo"))
          : "";
    }
  },
  // 离开页面销毁计时器
  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.timer2);
    this.timer = null;
    this.timer2 = null;
  },
  // watch:{
  //   // 如果信息提示展示了 就倒计时两分钟清空掉
  //   // closeBoxFlag:{
  //   //   immediate: true,
  //   //   deep: true,
  //   //   handler:function(val,old) {
  //   //     if(!this.closeBoxFlag ){
  //   //       //clearInterval(this.timer)
  //   //     }
  //   //     if(this.closeBoxFlag){
  //   //       this.timer2 = setInterval(() => {
  //   //         this.closeBoxFlag = false;
  //   //       }, 4000);
  //   //     }
  //   //   }
  //   // },
  //   // // 点击叉号状态 如果是点击了 则清空计时器 不进行倒计时
  //   // closeBoxclickFlag:{
  //   //   immediate: true,
  //   //   deep: true,
  //   //   handler:function(val,old) {
  //   //     if(!this.closeBoxclickFlag ){
  //   //       clearInterval(this.timer)
  //   //     }
  //   //   }
  //   // },
  //   // // 喇叭状态 如果是禁用状态 则清空计时器 不进行倒计时
  //   // showflag:{
  //   //   immediate: true,
  //   //   deep: true,
  //   //   handler:function(val,old) {
  //   //     if(this.showflag ){
  //   //       this.timer = setInterval(() => {
  //   //         this.getSelectReply()
  //   //         //clearInterval(this.timer);
  //   //       }, 30000);
  //   //     }else {
  //   //       clearInterval(this.timer)
  //   //     }
  //   //   }
  //   // }
  // },
  mounted() {
    this.getList()
    this.sourceType=this.$route.query.sourceType
    // 如果上个页面 打开新增个问题
    if(this.$route.query.type==='fk'){
      this.questionVisible = true
    }
    if(this.$route.query.type==='hf' && this.$route.query.id  ){
      this.appealVisible = true
      this.id=this.$route.query.id
      if(this.$route.query.type =='replay'){
        this.type='replay'
      }
    }
    //  进入页面调用查询未读接口
    // this.getSelectReply()
  },
  methods:{
    yijiejue(){
      this.filterFrom.pageNo = 1
      this.getList()
    },
    // 获取未读消息
    getSelectReply(){
      selectReply({}).then(res=>{
        if (res.data.code == 0){
          this.num = res.data.data
          this.showlaba = true
          this.showflag = true
          this.closeBoxFlag = true
        }else {
          this.num = 0
        }
      }).catch(err=> {
        this.num = 0
      })

    },
    closeNotice(flag){
      this.showflag = flag
      this.$forceUpdate()
      if (!this.showflag ){
        this.$message.warning('消息提醒已关闭')
      }
    },
    closeBox(){
      this.closeBoxclickFlag=false
      this.closeBoxFlag=false
    },
    sortClick(data){
      this.filterFrom = {
        ...this.filterFrom,
        pageNo: 1,
        // orderBy: sortStatus == "" ? "" : sortStatus == 1 ? "desc" : "createdDate
        // orderBy: data.sortName
        orderBy:  data.sortName? data.sortName+" desc":''
          // sortStatus == 0 ? "" : sortStatus == 2 ?   (data.sortName? data.sortName+" asc":'') : (data.sortName? data.sortName+" desc":''),
        // sortName:data.sortName,
      };
      this.getList()
    },
    onChange(searchStr) {
      this.filterFrom.content = searchStr
    },

    onSearch(content){
      this.todoList = [];
      this.filterFrom.pageNo = 1
      this.filterFrom = { ...this.filterFrom, content };
      this.getList();
      this.isSearch = false
    },
    // 加载更多
    loadMore() {
      this.filterFrom = {
        ...this.filterFrom,
        pageNo: this.filterFrom.pageNo + 1
      };
      this.pageLoadFlag = true;
      custProblemPage(this.filterFrom).then(res => {
        // 点击加载更多 拼接数组
        if ( res.data.data.content.length>0) {
          // res.data.data.content.forEach(item=>{
          //   item.evaluateStar = item.evaluateStar?item.evaluateStar:'' // 星级
          //   item.evaluateStarStr =  item.evaluateStarStr?item.evaluateStarStr:'' // 星级说明
          // })
        }
        this.todoList = this.todoList.concat(res.data.data.content)

        this.pageLoadFlag = false;
        this.totalRows = res.data.data.totalRows
        this.totalPages = res.data.data.totalPage
        this.currentPage = res.data.data.currentPage
      }).catch(error => {
        console.log(error)
      })
    },
    // 新增问题点击确定
    cancelReasonHandOk(id){
      this.getList()
      this.id = id
      this.appealVisible=true
      this.sourceType=this.$route.query.sourceType
    },
    //查看并回复
    viewAndHF(item,type,ss){
      this.type = type
      if(ss ==='ss'){
        this.SStype = true
        this.type = ''
      }else{
        this.SStype = false
      }

      this.id = item.id
      this.appealVisible=true

      // if(ss === 'ss'){
      //   custss({ id: this.id}).then(res=>{
      //     if(res.data.code == 0) {
      //       this.$message.success('申诉成功')
      //       this.appealVisible=true
      //       this.getList()
      //     } else {
      //       this.$message.warning(res.data.msg)
      //     }
      //   })
      // } else{
      //   this.appealVisible=true
      // }


    },
    // 评价问题
    pingjia(item){
      // "id":"363",    /*id*/
      //   "evaluateStar":5,  /*星数*/
      //   "evaluateContent":"good"   /*内容*/
      evaluate({id :item.id,evaluateStar:item.evaluateStarflag,evaluateContent:item.explain}).then(res=>{
        if(res.data.code == 1){
          this.$message.warning(res.data.msg)
        }else{
          this.getList()
        }
      })
    },
    // 关闭问题
    closeQuestion(item){
      this.closeId=item.id
      this.visibleTips = true
      this.mesageInfoTips='确认要关闭问题吗?'
      this.otiontype='close'
    },
    sure(){
      // 关闭问题
      if(this.otiontype=='close'){
        closeProble({id:this.closeId}).then(res=>{
          if(res.data.code == 1){
            this.$message.warning(res.data.msg)
          }else{
            this.getList()
          }
        })
      }

    },
    // 获取筛选条件id
    onFilterChange(checkedObj) {
      this.filterFrom = { ...this.filterFrom, ...checkedObj[0],pageNo: 1 };
      this.getList()

    },
    // 新增问题弹窗
    addBtn(){
      this.questionVisible=true
    },
    // 物流星级评分
    handleChooseStar(itemStart,item) {
      item.evaluateStarflag = itemStart.id
      item.startname = itemStart.name
      this.$forceUpdate()
    },
    // 查询列表
    getList(){
      let data = {
        ...this.filterFrom,
      }
      // this.pageLoadFlag = true;
      custProblemPage(data).then(res=>{
        this.todoList = res.data.data.content
        this.totalPages = res.data.data.totalPage
        this.currentPage = res.data.data.currentPage
        this.totalRows=res.data.data.totalRows
        this.pageLoadFlag = false;
        if ( this.todoList.length>0) {
          this.todoList.forEach(item=>{
            item.evaluateStarflag = ''
            item.startname = ''
          })
        }
      })
    },
  },



}