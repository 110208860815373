<template>
  <div>
    <a-modal
      v-model="visible"
      @ok="handleOk"
      @cancel="cancle"
      wrapClassName="cancelNamewww"
      :width="842"
      :closable="false"
      centered
      :footer="null"
      :maskClosable="false"

    >
      <a-spin :spinning="pageLoadFlag">
        <div class="modal_close" @click="cancle">
          <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
        </div>
        <div class="modal_title_box">
        <span class="modal_title">
          <i class="modal_title_line"></i>新建问题
        </span>
        </div>
        <div class="cancelContent">
          <div class="selectBox">

            <div class="everyBox therem">
              <p class="lable"><span style="color: #D9161C">*</span>问题描述：</p>
              <a-textarea style="width: 400px"
                          :maxLength="150"
                          placeholder="请描述您的问题(不超过150字)"
                          :auto-size="{ minRows: 3, maxRows: 30 }"
                          v-model="result"></a-textarea>
            </div>

            <div class="everyBox biaoqian laiyuan">
              <div class="biaoqianBox">
                <p class="lable" style="margin-top:4px"><span style="color: #D9161C">*</span>问题类型：</p>
               <div style="display: flex;flex-direction: column">
                 <div class="userSorce">
                   <div class="smallSorce"
                        v-for="(item,index)  in  questionType"
                        :key="index"
                        @click="changeTipsBtn(item.code)"
                        :class="item.code === firstCode?'smallSorceActice':'smallSorce'"
                   >
                     {{ item.name }}
                   </div>
                 </div>
                 <div class="userSorce">
                   <div class="smallSorce"
                        v-for="(item,index)  in  questionTwoType"
                        :key="index"
                        @click="changeTwoTipsBtn(item.code)"
                        :class="item.code === TwoCode?'smallSorceActice':'smallSorce'"
                        style="height: 28px;font-size: 12px;line-height: 28px"
                   >
                     {{ item.name }}
                   </div>
                 </div>
               </div>

              </div>
            </div>
            <div class="everyBox" style="margin-top:8px">
              <p class="lable">上传图片：</p>
              <div class="imgUpload">
                <a-upload
                  list-type="picture-card"
                  :file-list="fileList"
                  :before-upload="beforeUpload"
                  @change="handleChange"
                  :remove="handleRemove"
                  @preview="handlePreview"
                  style="display: flex;flex-direction: row-reverse"
                  accept="image/png, image/jpeg, image/jpg"
                >
                  <div v-if="fileList.length<3">
                    <a-icon style="font-size:26px;color:#aaa" type="plus" />
                  </div>
                </a-upload>
                <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                  <img alt="example" style="width: 100%" :src="previewImage" />
                </a-modal>
              </div>
              <p v-show="fileList.length<=0" style="font-size: 14px;font-weight: 400;color: #AAAAAA;margin-left: 6px">注：可上传图片任意格式(最多3张)，不超过20M</p>
            </div>
            <div class="everyBox therem">
              <p class="lable"><span style="color: #D9161C">*</span>联系方式：</p>
              <a-input v-model="name" style="width: 200px" placeholder="请输入姓名"></a-input>
              <a-input v-model="phone"  style="width: 200px;margin-left: 16px" placeholder="请输入手机号"></a-input>
            </div>
          </div>
        </div>
        <div class="footer">
          <h-btn
            class="fristBtn btn"
            :width="120"
            :height="40"
            content="取消"
            type="primary"
            fontSize="16"
            borderRadius="4"
            @click="cancle"
          >
          </h-btn>
          <h-btn
            class="btn"
            :width="120"
            :height="40"
            content="提交"
            type="primary"
            fontSize="16"
            borderRadius="4"
            @click="handleOk"
          >
          </h-btn>
        </div>
      </a-spin>
    </a-modal>

  </div>
</template>
<script>

import { publicPath } from "@/common/constant";
import axios from "axios";
import { category, createProble } from "@/views/center/consultNewPage/list/api";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
  components: {},
  data() {
    return {
      name:'',
      phone:'',
      firstCode:'',
      TwoCode:'',
      questionType:[],
      questionTwoType:[],
      gjList:[],// 跟进方式
      pageLoadFlag: false,// 整个页面的loading
      previewVisible: false,
      GJid:undefined,// 跟进方式id
      planGJId:undefined,// 计划跟进方式
      planTimeStr:'', //计划跟近时间
      result:'',//跟进结果
      fileList:[],
      fileIds:[],// 附件id
      urlArr:[],// 附件地址
      previewImage: '',
      userTipsList:[
        {
          code:'1',
          name:'测试1'
        }
      ],
      visibleTips: false,// 新建用户标签弹窗
      therrr:[],
      TipsIdTC:[],
      userTip:'',
      tipsArea:'',
      pageLoadFlagbq:false,

      TipsIds:[],//
      TipsIdName:[],
      theme:'',// 主题
      palngjList:[],// 计划跟进方式
      // outId:''

    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    sourceType: {
      type: String,
      default: ''
    },

  },

  watch: {

    visible:function(val, old) {
      if(val) {
        this.phone =this.$store.state.user.userInfo.account.mobile
        this.name=this.$store.state.user.userInfo.account.userName

      }
    },

  },
  mounted() {
    this.getTy()
  },
  methods: {
    // 首页获取分类
    async getTy(){
      const res = await this.getType('')
      this.questionType = res.data.data
    },
    // 获取类型
    async getType(code){
      let data ={
        parentId:code?code:''
      }
      return category(data)
    },

    async changeTipsBtn(item){
      this.firstCode = item
      // questionTwoType
      const res = await this.getType(this.firstCode)
      this.questionTwoType = res.data.data
    },
    changeTwoTipsBtn(item){
      this.TwoCode = item

    },

    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    beforeUpload(file) {
      if ((file.size) / 1024 > 20480) {
        this.$message.warning("附件大小不能大于50M");
        let timer = setTimeout(()=>{
          this.fileList = this.fileList.slice(0,-1)
          clearTimeout(timer)
        },1)
        this.pageLoadFlag = false
        return false;
      } else {
        this.fileList = [...this.fileList, file];
        const formData = new FormData();
        formData.append("file1", file);
        formData.append("fileType", "shopPotentialUser");
        // 上传接口/custAgentActivity/uploadFile.nd
        let config = {
          headers: { 'content-type': 'multipart/form-data' }
        }
        // 发送请求
        const BASE_API = publicPath;
        axios.post(BASE_API + '/comm/method=uploadFile.nd', formData, config).then(res => {
          if (res.data.message == '上传成功') {
            this.fileIds.push({
              id: res.data.businessId
            })
            this.urlArr.push( res.data.url)
          } else {
            this.$message.warning('上传失败', res.data.message)
          }
        })
        return false
      }
    },
    handleChange({ fileList }) {
      this.fileList = fileList;
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
      this.fileIds.splice(index, 1)
      this.urlArr.splice(index, 1);
    },

    // 修改产品信息时间
    changeTime(date, dateString) {
      this.planTimeStr = dateString;
    },

    handleOk() {
      if (!this.result){
        this.$message.warning('请选择问题描述')
        return
      }
      if (!this.TwoCode){
        this.$message.warning('请选择问题类型')
        return
      }
      // if (this.urlArr.length<=0){
      //   this.$message.warning('请上传图片')
      //   return
      // }
      if (!this.name ){
        this.$message.warning('请输入行吗')
        return
      }
      if ( !this.phone){
        this.$message.warning('请输入手机号')
        return
      }
      this.pageLoadFlag=true
      let data = {
        "content": this.result,      /*问题详情*/
        "custProblemCategoryId": this.TwoCode,   /*分类id*/
        "attachs": this.urlArr.join(','),       /* 图片*/
        "questionerName": this.name,       /*联系人*/
        "questionerTel": this.phone  /*联系电话*/
      }
      createProble(data).then(res=>{
        if(res.data.code == 0){
          this.$emit("update:visible", false);
          this.$emit("cancelReasonHandOk",res.data.data );
          this.pageLoadFlag=false
        } else{
          this.$message.warning(res.data.msg)
          this.pageLoadFlag=false
        }
      }).catch(error=>{
        this.pageLoadFlag=false
      })


    },
    cancle() {
      this.$emit("update:visible", false);
      if(this.sourceType=='homepage'){
        this.$router.push({
          path: "/index",
        });
      }
      this.$forceUpdate()
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    }

  }
};
</script>
<style lang="less" scoped>
/deep/ .cancelNamewww {

  .ant-modal-content {
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      padding: 30px 0px 30px 0px;

      .cancelContent {
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        line-height: 20px;
        max-height:600px;
        overflow:auto;


        .selectBox {
          //margin-top: 16px;
          display: flex;
          flex-direction: column;

          .everyBox {
            display: flex;
            align-items: center;
            margin-left: 78px;
            margin-top: 20px;

            .lable {
              width: 98px;
              margin-right: 8px;
              text-align: right;
              font-size: 14px;
              color: #262626;

              span {
                font-size: 14px;
                font-weight: 400;
                color: #D9161C;
              }
            }
            .imgUpload {
              /deep/.anticon{
                font-size: 22px;
              }
            }
            .ant-upload.ant-upload-select-picture-card {
              width: 88px;
              height: 88px;
              margin: 0px 8px 0px 0px;
              padding: 0;
              float: left;
              background-color: #FAFAFA;
              border-radius: 2px;
              border: 1px dashed #CCCCCC;
            }
            .ant-upload-list-picture-card-container{
              width: 88px!important;
              height: 88px!important;
              margin-bottom: 0px;
            }
            .ant-upload-list-picture-card .ant-upload-list-item{
              width: 88px;
              height: 88px;
              margin-right: 8px;
              margin-bottom: 0px;
            }




            .radioGroup {
              margin-left: 8px;
            }

            .userSorce {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              //align-items: center;
              max-width: 530px;

              .smallSorce {
                padding: 0 14px 0 14px;
                color: #00AAA6;
                height: 32px;
                border-radius: 16px;
                border: 1px solid #EEEEEE;
                text-align: center;
                line-height: 32px;
                margin-right: 8px;
                margin-bottom: 12px;
                cursor: pointer;
              }

              .smallSorceActice {
                cursor: pointer;
                height: 32px;
                background: #00AAA6;
                border-radius: 16px;
                text-align: center;
                line-height: 32px;
                padding: 0 14px 0 14px;
                color: #fff;
                margin-right: 8px;
                margin-bottom: 12px;
                border: none;
              }
            }
            .userSorce:last-child{
              width: 418px;
              background: #F7F7F7;
              border-radius: 5px;
              padding: 10px 10px 0 10px;
              margin-bottom: 16px;
              .smallSorce{
                background: #FFFFFF;
                border: none;
                margin-bottom: 10px;
              }
              .smallSorceActice{
                background: #00AAA6;
                color: #fff;
                border: none;
              }
            }

            .circleBox {
              width: 32px;
              height: 32px;
              border-radius: 16px;
              border: 1px solid #EEEEEE;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              img {
                width: 12px;
                height: 12px;
              }
            }

          }
          .therem {
            display: flex;
            align-items: flex-start;
            /deep/ textarea.ant-input {
              border: 1px solid #EEEEEE;
            }
          }

          .everyBox:first-child{
            margin-top: 40px;
          }



          .biaoqian {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .biaoqianBox {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start
            }

            .tips {
              font-size: 12px;
              font-weight: 400;
              color: #AAAAAA;
              margin-left: 109px;
              margin-top: -6px;
            }

          }
          .laiyuan {
            margin-top: 20px;
          }

          .adress {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            div {
              display: flex;
            }

            .userDsInputress {
              margin-top: 16px;
              margin-left: 106px;
              width: 500px;
            }
          }
        }


        .ant-tree-node-selected {
          background-color: red;
        }

        .ant-form {
          line-height: 32px;
        }

        .ant-form-item-control-wrapper {
          margin-left: 16px;
        }

        .ant-form-item-control {
          line-height: 32px;
        }

        .ant-form-item-label {
          line-height: 32px;
        }

        .ant-form-item {
          margin-bottom: 0;
        }

        .messageInfo {
          font-size: 12px;
          font-weight: 400;
          color: #AAAAAA;
          line-height: 12px;
          margin-top: 8px;
          margin-left: 86px;
        }
      }

      .footer {
        margin-top:40px;
        .fristBtn{
          width: 120px;
          height: 40px;
          border-radius: 4px;
          border: none;
          border: 1px solid #CCCCCC;
          color: #777777;
          background: #fff;
          text-shadow: none;
          margin-right: 24px;
          box-shadow: none;
        }
      }

      .modal_close {

        position: absolute;
        width: 42px;
        height: 42px;
        top: -30px;
        right: 0;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }

      .modal_title_box {
        text-align: center;
        //padding-bottom: 50px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }

    p {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }

    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding:44px 0px  30px 0;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }
  }

}


</style>