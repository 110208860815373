
import http from "@/utils/request";
// 筛选条件接口
export function custProblemPage(data) {
  return http({
    method: "post",
    url: "/custProblem/list.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data
  })
}

export function category(data) {
  return http({
    method: "get",
    url: "/custProblem/selectCategory.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    params:data
  })
}
// 发起咨询

export function createProble(data) {
  return http({
    method: "post",
    url: "/custProblem/createProblem.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data:data
  })
}

// 商家关闭

export function closeProble(data) {
  return http({
    method: "post",
    url: "/custProblem/custClose.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data:data
  })
}
// 提交
export function evaluate(data) {
  return http({
    method: "post",
    url: "/custProblem/custEvaluate.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data:data
  })
}
// 催办

export function custCb(data) {
  return http({
    method: "post",
    url: "/custProblem/custCb.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data:data
  })
}
// 获取详情
export function problemDetail(data) {
  return http({
    method: "get",
    url: "/custProblem/problemDetail.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    params:data
  })
}

// 商家回复
export function custReply(data) {
  return http({
    method: "post",
    url: "/custProblem/custReply.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data:data
  })
}
// 商家申诉
export function custss(data) {
  return http({
    method: "post",
    url: "/custProblem/custSs.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data:data
  })
}
///cust-problem/select-yw-reply.nd 查询未读回复数量
export function selectReply(data) {
  return http({
    method: "get",
    url: "/custProblem/selectYwReply.nd",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    params:data
  })
}
// 商家未解决
// /custProblem/unresolve.nd
export function unresolve(data) {
  return http({
    method: "POST",
    url: "/custProblem/unresolve.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data
  })
}

