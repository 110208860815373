<template>
  <div>
    <a-modal
      v-model="visible"
      @cancel="cancle"
      wrapClassName="cancelNamewww"
      :width="648"
      :closable="false"
      centered
      :footer="null"
      :maskClosable="false"

    >
      <a-spin :spinning="pageLoadFlag">
        <div class="modal_close" @click="cancle">
          <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
        </div>
        <div class="cancelContent">
          <p class="createTime"><span style="margin-right: 16px;">{{detailInfo.code}}</span>{{detailInfo.createdDateStr}}创建</p>
          <p class="questionType">
            <span class="typeTitle">问题类型</span>
            <span v-if="detailInfo.xtwStatus !== 'CLOSED' && detailInfo.xtwStatus !== 'EVALUATE'" class="rightIcon" @click="cb"><i class="iconfont icon-cuiban" />催办</span>
          </p>
          <p class="leixing">
            <span>{{detailInfo.custProblemCategorName||''}}</span>
          </p>
<!--          问题详情-->
          <div class="detailBox">
            <p class="detailTip">问题详情</p>
            <div class="content">
              {{detailInfo.content||''}}
              <div class="imgBox">
                <div  v-for="(item,index) in attachsArr" :key="index" >
                  <img @click="openImg(item)" :src="item" alt="">
                </div>
              </div>
            </div>

          </div>
     <!--           进度-->
          <div class="process">
            <div class="bottom-wrap" >
              <div class="content-wrap">
                <div class="box-wrap clearfix" v-for="(item,index) in detailInfo.custProblemDetail" :key="index">
                  <div class="left-box">
                    <span>{{ item.createdDateStr }}</span>
                    <div class="point" :class="{ 'point-active': index == 0 }">
                      <div class="point-inner"></div>
                    </div>
                  </div>
                  <div class="info-wrap">
                    <div class="title-wrap" :class="{ 'titleActive': index == 0 }">{{item.creator}}</div>
                    <div class="imgBox">
                      <div
                        v-for="(itemFile,itemFileIndex) in item.attachsList"
                        :key="itemFileIndex"
                      >
                        <img style='cursor:pointer;' @click="openImg(itemFile)" :src="itemFile" alt="">
                      </div>

                    </div>
                    <div class="text-wrap" :class="{ 'text-wrapA': index == 0 }">{{item.opeContent}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%;" :src="previewImage" />
          </a-modal>
<!--           问题解决模块-->
          <div class="wtjj" v-if="detailInfo.closeBtnFlag">
            <p>问题解决</p>
            <a-radio-group class="group" v-model="resolved"  @change="solveAproblem">
              <a-radio value="1">已解决(问题关闭)</a-radio>
              <a-radio value="0" style="margin-left:8px">未解决</a-radio>
            </a-radio-group>
          </div>
<!--        评价-->
          <div class="pjBox" v-if="detailInfo.xtwStatus == 'EVALUATE'&&!this.isSS">
            <div class="consulting-box">
              <div class="evaluate-title">邀您评价</div>
              <div class="evaluate-star-list">
                <div v-for="itemStart in evaluateStatusList"  :key=itemStart.id>
                  <div class="evaluate-star-item" @click="handleChooseStar(itemStart)" :class="evaluateStar == itemStart.id ? 'active' : '' ">
                    <div  class="icon iconfont icon-yishoucang star-item " :class="evaluateStar >= itemStart.id ? 'start-active' : '' "></div>
                    <p class="startRemark" :class="evaluateStar === itemStart.id ? 'remark-active' : '' ">{{ itemStart.name }}</p>
                  </div>
                </div>
              </div>
              <p class="rightTxt">{{evaluateStarStr}}</p>
            </div>
            <a-textarea class='textarea' v-model="explain" :maxLength="100"  placeholder="请填写您的留言(不超过100字)" />
          </div>


          <!--           已经评价-->
          <div class="pjBox" v-if="detailInfo.xtwStatus=='CLOSED'">
            <div class="consulting-box">
              <div class="evaluate-title">感谢评价</div>
              <div class="evaluate-star-list">
                <div v-for="(itemStart,startIndex) in 5"  :key=startIndex>
                  <div class="evaluate-star-item"  :class="evaluateInfo.evaluateStar == itemStart ? 'active' : '' ">
                    <div  class="icon iconfont icon-yishoucang star-item " :class="evaluateInfo.evaluateStar >= itemStart ? 'start-active' : '' "></div>
                  </div>
                </div>
              </div>
              <p  class="rightTxt">{{evaluateInfo.evaluateStarStr }}</p>
            </div>
            <!--         评价玩以后-->
            <div v-if="detailInfo.xtwStatus=='CLOSED'&&detailInfo.evaluateContent" class="disbaledPJ">
              <p>{{detailInfo.evaluateContent}}
              </p>
            </div>
          </div>

<!--           图片上传加评价-->
          <div class="upLoadImg"  v-if="(isShowRecover&&(detailInfo.xtwStatus=='UNDERWAY'||detailInfo.xtwStatus=='WAITREPLY'||detailInfo.xtwStatus=='APPEALING'))||isSS">
            <a-upload
              v-if="fileList.length<3"
              :file-list="fileList"
              :before-upload="beforeUpload"
              @change="handleChange"
              :remove="handleRemove"
              @preview="handlePreview"
              showUploadList="false"
              accept="image/*"
            >
              <i style=""  class="iconfont icon-tupian-jianpan"/>
<!--              <img style="width:20px;height:20px" :title="'上传图片'" src="./../../../../../assets/order/图片-键盘@1px.png" alt="">-->
<!--              <img style="width:20px;height:20px" :title="'上传图片'" src="./../../../../../assets/order/图片-键盘@2x.png" alt="">-->
            </a-upload>
            <img  v-if="fileList.length>=3" style="width:20px;height:20px" :title="'上传图片'" src="./../../../../../assets/order/图片-键盘@2x.png" alt="">
            <div class="fileListBox">
              <div
                class="fileListItem"
                v-for="(item,index) in urlArr"
                :key="index"
              >
                <i  class="iconfont icon-guanbi-gouwuchexuanzhong" @click="handleRemove"/>
               <div class="imgBox" @mouseenter="enterImg(item)">
                 <img style='cursor:pointer;' :src="item" @click="openImg(item)" alt="">
               </div>
              </div>
            </div>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%;" :src="previewImage" />
            </a-modal>
            <a-textarea style="margin-top:4px;" class="textarea"  v-model="textarea" placeholder="请描述您的问题"></a-textarea>
            <div class="sumbitBox">
              <h-btn
                class="btn"
                :width="80"
                :height="32"
                content="提交"
                type="primary"
                fontSize="14"
                borderRadius="4"
                @click="postCustReply"
              />
            </div>
          </div>
        </div>
<!--        ['UNDERWAY', 'WAITREPLY', 'APPEALING']-->
        <div class="footer" v-if="(detailInfo.xtwStatus == 'UNDERWAY'||detailInfo.xtwStatus == 'APPEALING'||detailInfo.xtwStatus == 'WAITREPLY')&&!isShowRecover">
          <h-btn
            class="btn"
            :width="148"
            :height="40"
            content="回复"
            type="primary"
            fontSize="16"
            borderRadius="4"
            @click="hf"
          />
        </div>
        <div  class="footer" v-if="!this.isSS && (detailInfo.ssButtonFlag||detailInfo.xtwStatus == 'EVALUATE')">
          <h-btn
            v-if="detailInfo.ssButtonFlag &&!this.isSS"
            class="btn fristBtn"
            :width="148"
            :height="40"
            content="发起申诉"
            type="primary"
            fontSize="16"
            borderRadius="4"
            @click="ss"
          />
          <h-btn
            v-if="detailInfo.xtwStatus == 'EVALUATE'&&!this.isSS"
            class="btn"
            :width="148"
            :height="40"
            content="提交评价"
            type="primary"
            fontSize="16"
            borderRadius="4"
            @click="pingjia"
          />
        </div>

      </a-spin>
      <delete-modal
        :visible.sync="visibleTips"
        :info="mesageInfoTips"
        @deleteHandOk="sure"
        @deleteHandCancle="deleteHandCancle"

      ></delete-modal>
    </a-modal>

  </div>
</template>
<script>

import { publicPath } from "@/common/constant";
import axios from "axios";
import {
  category,
  closeProble,
  // createProble,
  custCb,
  unresolve,
  custReply, custss, evaluate,
  problemDetail
} from "@/views/center/consultNewPage/list/api";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
  components: {},
  data() {
    return {
      name:'',
      phone:'',
      firstCode:'',
      TwoCode:'',
      questionType:[],
      questionTwoType:[],
      pageLoadFlag: false,// 整个页面的loading
      previewVisible: false,
      result:'',//
      fileList:[],
      fileIds:[],// 附件id
      urlArr:[],// 附件地址
      previewImage: '',
      pageLoadFlagbq:false,
      logistics:[],
      evaluateInfo:{},
      evaluateList: [], // 物流评价标签
      evaluateStarStr:'',
      evaluateStar:'',
      explain:'',
      evaluateStatusList: [
        {id: 1, name: '非常不满意'},
        {id: 2, name: '不满意'},
        {id: 3, name: '一般般'},
        {id: 4, name: '满意'},
        {id: 5, name: '非常满意'},
      ],
      resolved:'',
      visibleTips:false,
      mesageInfoTips:'',
      detailInfo:{},//详细信息
      attachsArr:[],
      isShowRecover:false,
      isSS:false
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    SStype: {
      type: Boolean,
      default: false
    },
    sourceType: {
      type: String,
      default: ''
    },
  },
  watch: {
    visible:function(val, old) {
      if(val) {
        this.getInfo()
        if(this.type =='replay'){
          this.isShowRecover = true
          this.isSS = this.SStype
        }else{
          this.evaluateInfo={};
            this.evaluateList=[]; // 物流评价标签
            this.evaluateStarStr='';
            this.evaluateStar='';
            this.explain='';
            this.isShowRecover = false
          this.isSS = this.SStype
        }
      } else {
        this.type =''
        this.detailInfo = {}
        this.isShowRecover = false
        this.isSS = false
      }
    }
  },
  mounted() {
    this.getTy()
  },
  methods: {
    enterImg(item){
    },
    // 评价问题
    pingjia(item){
      evaluate({id :this.id,evaluateStar:this.evaluateStar,evaluateContent:this.explain}).then(res=>{
        if(res.data.code == 1){
          this.$message.warning(res.data.msg)
        }else{
          this.getInfo()
          this.$emit("yijiejue", );
        }
      })
    },
    // 打开留言弹窗
    hf(){
      this.isShowRecover = true
      this.isSS = false
    },
    // 获取详情
    getInfo(){
      problemDetail({id:this.id}).then(res=>{
       if(res.data.code == 0){
         this.evaluateInfo =  { // 评价信息
           evaluateStar: res.data.data.evaluateStar?res.data.data.evaluateStar:0,
           evaluateStarStr: res.data.data.evaluateStarStr ? res.data.data.evaluateStarStr: '',
           evaluateContent: res.data.data.evaluateContent ? res.data.data.evaluateContent: ''
         }
         if(res.data && res.data.data.custProblemDetail && res.data.data.custProblemDetail.length > 0) {
           res.data.data.custProblemDetail.forEach(el => {
             el.attachsList = el.attachs ? el.attachs.split(',') : []
           })
         }
         this.detailInfo = res.data.data
         this.attachsArr = res.data.data.attachs? res.data.data.attachs.split(','):[]
       } else{
         this.detailInfo ={}
         this.evaluateInfo =  {}
       }
      })
    },
    // 发起申诉
    ss(){
      this.isSS = true
      // this.isShowRecover = true
      // custss({ id: this.id}).then(res=>{
      //   if(res.data.code == 0) {
      //     this.$message.success('申诉成功')
      //     this.getInfo()
      //     this.$emit("yijiejue", );
      //   } else {
      //     this.$message.warning(res.data.msg)
      //   }
      // })
    },
    // 提交回复
    postCustReply(){
      if(this.isSS){
        custss({
          id:this.id,
          attachs:this.urlArr.length>0?this.urlArr.join(','):'',
          content:this.textarea
        }).then(res=>{
          if(res.data.code == 0) {
            this.$message.success('申诉成功')
            this.textarea=''
            this.urlArr=[]
            this.fileList=[]
            this.fileIds=[]
            this.getInfo()
            // 此处时候直接展示回复框  现在设置为true 如果想要展示回复按钮的话 isShowRecover就设为为false
            // this.isShowRecover = true
            this.isSS = false
            this.$emit("yijiejue", );
          } else {
            this.textarea=''
            this.urlArr=[]
            this.fileList=[]
            this.fileIds=[]
            this.$message.warning(res.data.msg)
            this.getInfo()
            this.isSS = false
          }
        })
      }else{
        custReply({
          id:this.id,
          attachs:this.urlArr.length>0?this.urlArr.join(','):'',
          content:this.textarea
        }).then(res=>{
          if(res.data.code == 0) {
            this.$message.success('回复成功')
            this.textarea=''
            this.urlArr=[]
            this.fileList=[]
            this.fileIds=[]
            this.getInfo()
            this.$emit("yijiejue", );
          } else {
            this.textarea=''
            this.urlArr=[]
            this.fileList=[]
            this.fileIds=[]
            this.$message.warning(res.data.msg)
          }
        })
      }

      // http://xtwdev.clouddev.hisense.com/#/login
    },

    openImg(item){
      this.previewVisible=true
      this.previewImage=item
    },
    // 问题解决弹窗
    solveAproblem(){
      if(this.resolved==1){
        this.visibleTips = true
        this.mesageInfoTips='确认问题已解决吗?'
      }else{
        unresolve({ id: this.id }).then(res =>{
          if(res.data.code == 0) {
            this.getInfo()
            this.resolved = ''
            this.$emit("yijiejue", );
          }else{
            this.resolved = ''
            this.$message.warning(res.data.msg)
          }
        })
      }
    },
    // 解决问题弹窗点击取消操作
    deleteHandCancle(){
      this.resolved=''
    },
    sure(){
      this.resolved=1
      closeProble({id:this.id}).then(res=>{
        // if(res.data.code == 1){
        //   this.$message.warning(res.data.msg)
        // }
        if(res.data.code == 0) {
          this.$message.success('问题已关闭')
          this.getInfo()
          this.$emit("yijiejue" );
        } else {
          this.$message.warning(res.data.msg)
        }
      })
    },
    // 催办
    cb(){
      custCb({id:this.id}).then(res=>{
        if(res.data.code == 0) {
          this.$message.success('催办成功')
          this.getInfo()
        } else {
          this.$message.warning(res.data.msg)
        }
      })
    },
    // 物流星级评分
    handleChooseStar(itemStart) {
      this.evaluateStar = itemStart.id
      this.evaluateStarStr = itemStart.name
      this.$forceUpdate()
    },
    // 首页获取分类
    async getTy(){
      const res = await this.getType('')
      this.questionType = res.data.data
    },
    // 获取类型
    async getType(code){
      let data ={
        parentId:code?code:''
      }
      return category(data)
    },
    async changeTipsBtn(item){
      this.firstCode = item
      // questionTwoType
      const res = await this.getType(this.firstCode)
      this.questionTwoType = res.data.data
    },
    changeTwoTipsBtn(item){
      this.TwoCode = item
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      const formData = new FormData();
      formData.append("file1", file);
      formData.append("fileType", "shopPotentialUser");
      // 上传接口/custAgentActivity/uploadFile.nd
      let config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      // 发送请求
      this.Canbutton = true
      const BASE_API = publicPath;
      axios.post(BASE_API + '/comm/method=uploadFile.nd', formData, config).then(res => {
        if (res.data.message == '上传成功') {
          this.fileIds.push({
            id: res.data.businessId
          })
          this.urlArr.push( res.data.url)
        } else {
          this.$message.warning('上传失败', res.data.message)
        }
        this.Canbutton = false
      })
      return false;
    },
    handleChange({ fileList }) {
      this.fileList = fileList;
    },
    handleRemove(file) {
      const index = file;
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
      this.fileIds.splice(index, 1)
      this.urlArr.splice(index, 1);
    },


    handleOk() {

    },
    cancle() {
      if(this.sourceType=='homepage'){
        this.$router.push({
          path: "/index",
        });
      }
      this.$emit("update:visible", false);
      this.$forceUpdate()
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    }

  }
};
</script>
<style lang="less" scoped>
/deep/ .cancelNamewww {
  .ant-modal-content {
    .ant-input:focus{
      box-shadow:none;
      border-color:#fff;
      -webkit-box-shadow:none;
      border-right-width:0;
    }
    text-align: center;
    border-radius: 10px;
    .ant-modal-body {
      padding: 21px 0px 24px 24px;
      .cancelContent {
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        line-height: 20px;
        padding-right: 24px;
        //max-height:600px;
        //overflow:auto;
        .createTime{
          height: 17px;
          font-size: 12px;
          font-weight: 400;
          color: #AAAAAA;
          line-height: 17px;
          margin-bottom: 8px;
        }
        .questionType{
          display:flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom:6px;
          .typeTitle{
            font-size: 14px;
            font-weight: 500;
            color: #262626;
          }
          .rightIcon{
            cursor: pointer;
            display: flex;
            flex-direction: row;
            height: 20px;
            font-size: 14px;
            color: #00C4A9;
            line-height: 20px;
            i {
              font-size:18px;
              margin-right: 4px;
            }
          }
        }
        .leixing{
          width: 600px;
          padding:12px;
          background: #F7F7F7;
          border-radius: 6px;
          display:flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 16px;
          font-size:12px;
          line-height:12px;
          span {
            font-size: 12px;
            font-weight: 400;
            color: #262626;
          }
        }
        .detailBox{
          .detailTip{
            font-size: 14px;
            font-weight: 500;
            color: #262626;
          }
          .content{
            margin-top:6px;
            width: 600px;
            padding:12px;
            background: linear-gradient(90deg, #EEF9F9 0%, #FBFBFB 100%);
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            font-size:12px;
            line-height:12px;
            .imgBox{
              display: flex;
              flex-direction: row;
              div{
                cursor: pointer;
                width:54px;
                height: 54px;
                border-radius: 6px;
                background: #fff;
                margin-right: 6px;
                margin-top: 6px;
                overflow: hidden;
                img{
                  width:54px;
                  height: 54px;
                }
              }


            }
          }

        }
        .process{
          margin-top:16px;
          .bottom-wrap {
            margin: 0 auto;
            width: 602px;
            background: #FFFFFF;
            margin-top: 0px;
            //padding-top: 24px;
            padding-bottom: 0px;
            + .info-wrap{
              margin-top: 0;
            }
            .title-wrap{
              text-align: left;
              padding-left: 24px;
              font-size: 16px;
              font-weight: 500;
              color: #262626;
              height: 22px;
            }
            .titleActive{
              color:#262626;
            }

            .content-wrap{
              height:140px;
              overflow: auto;
              padding-bottom: 20px;
              margin-bottom:20px;
              padding-top:2px;
              .box-wrap{
                width: 100%;
                overflow: visible;
                &:last-child{
                  .info-wrap{
                    border-left: none ;
                    .text-wrap{
                      padding-bottom: 0;
                    }
                  }
                }
                .left-box{
                  float: left;
                  position: relative;
                  width: 145px;
                  height: 16px;
                  font-size: 12px;
                  font-weight: 400;
                  color: #262626;
                  line-height: 16px;
                  text-align: left;
                  .point{
                    position: absolute;
                    right: -10px;
                    top: -2px;
                    width: 20px;
                    height: 20px;
                    background: #F7F7F7;
                    border-radius: 50%;
                    line-height: 20px;
                    text-align: center;
                    .point-inner{
                      position: relative;
                      // top: -0.5px;
                      width: 10px;
                      height: 10px;
                      display: inline-block;
                      border-radius: 50%;
                      background: #FFFFFF;
                      border: 3px solid #ccc;
                    }
                  }
                  .point-active{
                    background: #ECF8F8;
                    .point-inner{
                      border: 3px solid #00AAA6;
                    }
                  }
                }
                .info-wrap{
                  float: left;
                  border-left: 1px solid #EEEEEE ;
                  padding-left: 25px;
                  .title-wrap{
                    height: 14px;
                    font-size: 14px;
                    //font-weight: 600;
                    color: #777;
                    line-height: 14px;
                    margin-left: 0;
                    padding-left: 0;
                  }
                  .title-wrapA{
                    color: #00AAA6;
                  }
                  .titleActive{
                    color: #262626;
                  }
                  .text-wrap{
                    //width: 602px !important;
                    line-height: 20px !important;
                    text-align: left !important;
                    padding-top: 6px;
                    padding-bottom: 24px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #aaa;
                    margin-left: 0;
                    width:400px;
                  }
                  .text-wrapA{
                    color: #262626;
                  }
                  .imgBox{
                    display: flex;
                    flex-direction: row;
                    div{
                      width:54px;
                      height: 54px;
                      border-radius: 6px;
                      background: #fff;
                      margin-right: 6px;
                      margin-top: 6px;
                      overflow: hidden;
                      img{
                        width:54px;
                        height: 54px;
                      }
                    }


                  }
                }
              }
            }
          }
        }
        .wtjj{
          width: 604px;
          height: 54px;
          background: linear-gradient(90deg, #EEF9F9 0%, #FBFBFB 100%);
          border-radius: 6px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          padding-left: 12px;
          .group{
            margin-left:30px;
          }
        }
        .pjBox{
          // 评价星星
          .consulting-box{
            width: 600px;
            height: 76px;
            background: #FFFAF6;
            border-radius: 6px;
            margin-top:14px;
            margin-bottom: 12px;
            display:flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding-left:24px;
            .evaluate-title{
              font-size: 16px;
              font-weight: 500;
              color: #262626;
            }
            .evaluate-star-list{
              display: flex;
              align-items: center;
              //justify-content: space-between;
              .evaluate-star-item{
                width: 60px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 10px;
                font-weight: 400;
                color: #AAAAAA;
                margin-left:10px;
                line-height:37px;
                .star-item{
                  cursor: pointer;
                  font-size: 28px;
                  color: #CCCCCC;
                  font-weight: 400;
                  height:37px;
                }
                .start-active{
                  color: #FF8F3C;
                  font-weight: 400;
                }
                &.active{
                  color: #FF8F3C;
                  font-weight: 400;
                }
                .startRemark{
                  height: 14px;
                  font-size: 10px;
                  font-weight: 400;
                  color: #AAAAAA;
                  line-height: 14px;
                }
                .remark-active{
                  color: #FF8F3C;
                }
              }
            }
            .rightTxt{
              font-size: 14px;
              font-weight: 500;
              color: #FF8F3C;
              line-height: 20px;
              margin-left: 56px;
            }
            .consulting-desc-list{
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              .consulting-desc-item{
                margin-top: 8px;
                width: 116px;
                height: 30px;
                border-radius: 15px;
                border: 1px solid #CCCCCC;
                font-size: 14px;
                font-weight: 400;
                color: #777777;
                line-height: 30px;
                text-align: center;
                &.active{
                  color: #00AAA6;
                  border-color: #00AAA6;
                }
              }
            }
            .consulting-message{
              position: relative;
              margin-top: 18px;
              height: 88px;
              background: #F7F7F7;
              border-radius: 8px;
              .message-textarea{
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                padding: 14px 12px;
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
                color: #262626;
              }
              .words-num{
                position: absolute;
                right: 12px;
                bottom: 14px;
                z-index: 1;
                font-size:12px;
                font-weight: 400;
                color: #AAAAAA;
                line-height: 12px;
              }
            }
          }
          // 评价
          .textarea{
            width: 600px;
            height: 70px;
            background: #F7F7F7;
            border-radius: 6px;
            margin-bottom:12px;
            resize:none;
            border:0;
            &::placeholder {
              color: #aaa!important;
            }
          }
          /deep/.ant-input:focus{
            box-shadow:none;
            border-color:#fff;
            -webkit-box-shadow:none;
            border-right-width:0;
          }
          .disbaledPJ{
            width: 600px;
            min-height: 36px;
            background: #F7F7F7;
            border-radius: 6px;
            margin-top:12px;
            display: flex;
            padding:8px 12px 8px 12px;
            align-items: center;
            justify-content: flex-start;
            p {
              color: #777777;
              font-size: 14px;
              font-weight: 400;
            }
          }
        }

        .upLoadImg{
          .iconfont{
            font-size: 18px;
            cursor: pointer;

          }
          .iconfont:hover{
            color: #00AAA6;
          }
          .sumbitBox{
            width:100%;
            margin-top: 4px;
            display: flex;
            justify-content: flex-end;
          }
          width: 600px;
          //height: 181px;
          background: #F7F7F7;
          border-radius: 6px;
          margin-top:16px;
          padding:12px 16px 16px 14px;
          img{
            width:100%;
            height: 100%;
          }
          .ant-upload-list {
            display:none!important;
          }
          // 附件样式
          .fileListBox {
            display: flex;
            flex-wrap: wrap;
            width: 1000px;
            align-items: center;

            .fileListItem {
              display: flex;
              border: none;
              align-items: center;
              justify-content: center;
              margin: 8px 8px 8px 0px;
              background: #fff;
              position: relative;
              .imgBox{
                width: 47px;
                height: 49px;
                border-radius: 4px;
                overflow:hidden;
              }
              .iconfont{
                position: absolute;
                right: -7px;
                top: -7px;
                color:#00AAA6;
                cursor: pointer;
                font-size: 12px;
              }
            }


          }
          // 评价
          .textarea{
            width: 600px;
            height: 70px;
            background: #fff;
            border-radius: 6px;
            margin-bottom:12px;
            resize:none;
            border:0;
          }

        }
      }
      .footer {
        padding-right: 24px;
        margin-top:40px;
        display:flex;
        justify-content: center;

        .fristBtn{
          width: 120px;
          height: 40px;
          border-radius: 4px;
          border: none;
          border: 1px solid #00AAA6;
          color: #00AAA6;
          background: #fff;
          text-shadow: none;
          margin-right: 24px;
          box-shadow: none;
        }
      }
      .modal_close {
        position: absolute;
        width: 42px;
        height: 42px;
        top: -21px;
        right: 0;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;
        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }
      .modal_close:hover {
        background: #E4F8F7;
        .modal_close_icon {
          color: #08ABA8;
        }
      }
      .modal_title_box {
        text-align: center;
        //padding-bottom: 50px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }

  }

}

</style>